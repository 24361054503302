<!--
  + 这里只有法人和非法人的入驻审核
  + 挂靠的审核在前端有详情
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="机构入驻审核" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <!--  机构角色， 0 - 挂靠关系 / 1 - 法人关系 -->
        <div style="margin-top:20px;" v-if="formData.organizationRole === globalVar.ORG_ROLE_CORPORATE">
          <info-header title="机构信息" />
          <div style="padding:20px 0;">
            <info-item
              :label-width="labelWidth"
              label="机构头像"
              value-type="image"
              :value="formData.avatarUrl">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="组织名称"
              :value="formData.organization.name">
            </info-item>
            <info-item
              v-if="formData.organization.orgType === 0"
              :label-width="labelWidth"
              label="负责人姓名"
              :value="formData.organization.personCharge">
            </info-item>
            <!-- 机构类别: 0 - 非法人机构, 1 - 法人机构  -->
            <template v-if="formData.organization.orgType === 1">
              <info-item
                :label-width="labelWidth"
                label="组织类别"
                :value="formData.organization.orgCategory">
              </info-item>
              <info-item
                :label-width="labelWidth"
                label="统一社会信用代码"
                :value="formData.organization.creditCode">
              </info-item>
              <info-item
                :label-width="labelWidth"
                label="统一社会信用代码/营业执照"
                value-type="image-list"
                image-width="350px"
                image-height="220px"
                image-fit="contain"
                :value="formData.organization.businessLicense ? formData.organization.businessLicense.split(',') : []">
              </info-item>
              <info-item
                :label-width="labelWidth"
                label="法定代表人"
                :value="formData.organization.legalPerson">
              </info-item>
              <info-item
                :label-width="labelWidth"
                label="法人身份证号"
                :value="formData.organization.legalPersonIdCard">
              </info-item>
            </template>
            <!-- 非法人机构入驻存在 -->
            <info-item
              v-if="formData.organization.orgType === 0"
              :label-width="labelWidth"
              label="人员情况"
              :value="educationSituation">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="行业领域"
              :value="getIndustrySectorNames(formData.serviceTrade)">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="产业特长"
              :value="getTechnicalNames(formData.industryExpertise)">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="服务方向"
              :value="formData.serviceDirection">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="机构服务案例"
              :value="formData.serviceCase">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="机构简介"
              :value="formData.organization.description">
            </info-item>
            <template v-if="formData.organization.orgType === 1">
              <info-item
                :label-width="labelWidth"
                label="机构网站"
                :value="formData.organization.website">
              </info-item>
            </template>
          </div>
        </div>

        <info-header title="其他信息" />
        <div style="padding: 20px 0;">
          <info-item
            :label-width="labelWidth"
            label="联系人"
            :value="formData.username">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="性别"
            :value="{
              [globalVar.USER_GENDER_0]: '未知',
              [globalVar.USER_GENDER_1]: '男',
              [globalVar.USER_GENDER_2]: '女'
            }[formData.gender]">
          </info-item>
          <template v-if="formData.organization && formData.organization.orgType === 0">
            <info-item
              :label-width="labelWidth"
              label="身份证号"
              :value="formData.idCard">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="职称/所获资格认证"
              :value="formData.positionalTitleText">
            </info-item>
            <!-- 非法人机构入驻存在 -->
            <info-item
              :label-width="labelWidth"
              label="最高学历"
              :value="formData.highestEducation">
            </info-item>
          </template>
          <info-item
            :label-width="labelWidth"
            label="联系手机"
            :value="formData.contactNumber">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="联系邮箱"
            :value="formData.contactEmail">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="联系地址"
            :value="contactAddress">
          </info-item>
        </div>

        <div
          v-if="formData.registerStatus === globalVar.REGISTER_STATUS_PENDING"
          :style="{
            paddingLeft: labelWidth,
            marginTop: '20px'
          }">
          <el-button
            type="primary"
            size="small"
            :loading="btnLoading"
            @click="onAudit(globalVar.REGISTER_STATUS_PASS)">
            通过
          </el-button>
          <el-button
            type="danger"
            size="small"
            plain
            :loading="btnLoading"
            @click="onAudit(globalVar.REGISTER_STATUS_REJECT)">
            驳回
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import cities from '@/assets/json/cities.json'
import globalVar from '@/configs/globalVar'
export default {
  data () {
    return {
      loading: false,
      labelWidth: '120px',
      formData: {},
      btnLoading: false
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    getTechnicalNames () {
      return (technical) => {
        const ids = technical ? technical.split(',') : []
        let result = ''

        this.technicalList.forEach(item => {
          if (ids.includes(item.id)) {
            result += result === '' ? item.name : ',' + item.name
          }
        })
        return result
      }
    },
    // 行业领域下拉列表
    industrySectorList () {
      return this.$store.state.industrySectorList
    },
    // 拿到行业领域的id字符串
    getIndustrySectorNames () {
      return (idList = []) => {
        let result = ''

        this.industrySectorList.forEach(item => {
          if (idList.includes(item.id)) {
            result += result === '' ? item.name : ',' + item.name
          }
        })
        return result
      }
    },
    // 人员情况
    educationSituation () {
      if (this.formData.organization && this.formData.organization.educationSituation) {
        const nums = this.formData.organization.educationSituation.split(',')
        return `总人数：${nums[0]}人，硕士（含以上）：${nums[1]}人，大学本科：${nums[2]}人，大专及（以下）：${nums[3]}人`
      } else {
        return ''
      }
    },
    // 联系地址
    contactAddress () {
      const { addressProvince, addressCity, addressCounty, contactAddress } = this.formData

      if (!addressProvince && !addressCity && !addressCounty) return ''

      const one = cities.find(item => {
        return item.value === addressProvince
      })
      if (!one) {
        return ''
      }
      const two = one.children.find(item => {
        return item.value === addressCity
      })
      if (!two) {
        return ''
      }
      const three = two.children.find(item => {
        return item.value === addressCounty
      })
      if (!three) {
        return ''
      }

      return `${one.label}、${two.label}、${three.label}、${contactAddress}`
    }
  },
  created () {
    this.getAuditDetail()
  },
  methods: {
    // 获取账号详情
    getAuditDetail () {
      this.loading = true
      api.getUserItemInfo(this.id).then(res => {
        if (res.data.code === 0) {
          this.formData = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 通过或拒绝
    onAudit (logicStatus) {
      // 如果是已拒绝 则需要填写拒绝理由
      if (logicStatus === globalVar.REGISTER_STATUS_REJECT) {
        this.$prompt('请输入驳回原因', '提示', {
          confirmButtonText: '驳回',
          cancelButtonText: '取消',
          inputPattern: /\S/,
          inputErrorMessage: '请输入驳回原因',
          inputPlaceholder: '请输入驳回原因'
        }).then(({ value }) => {
          this.auditConfirm('', '', logicStatus, value)
        }).catch(() => {
        })
      } else {
        this.$msgbox({
          title: '确认',
          message: '请确认你的操作',
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              this.auditConfirm(instance, done, logicStatus)
            } else {
              done()
            }
          }
        })
      }
    },
    /**
     * 状态(审核)， 2 - 已通过, 3 - 已驳回
     */
    auditConfirm (instance, done, logicStatus, reason) {
      if (instance) {
        instance.confirmButtonLoading = true
      }
      this.btnLoading = true
      api.auditRzDetail(this.id, {
        logicStatus,
        reason
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.getAuditDetail()
        } else {
          this.$message.error(res.data.message)
        }
        done && done()
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
        if (instance) {
          instance.confirmButtonLoading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;
    }
  }
}
</style>
